import React from "react";
import { PageLayout } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class OurStoryPage extends React.PureComponent {
  render() {
    return (
      <WebsiteLayout>
        <PageLayout className={"our-story-page"} hero={null}>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12} textAlign={"center"}>
              <h2>
                The Oldest Continually Operating Tavern West of the Mississippi
              </h2>
              <p>
                John Pfautsch and Phillip Kuhn, in partnership, erected the
                Concert Hall in the spring and early summer of 1878. John
                Pfautsch came from an early Hermann family, immigrating with his
                mother in 1847. Together with his brother, Eustach, John built a
                saloon and residence on East Third Street. Phillip Kuhn was in
                his early twenties when the hall was built, but had experience
                running a saloon and restaurant near the railroad depot for many
                years.
              </p>
              <p>
                Together they visited St. Louis during the winter to get ideas
                as to what sort of building their new hall should be. In March
                of 1878 they began its construction employing St. Louis
                contractors for the lumber and iron work. One year after
                completion, the "able artist" Henry German Sr. painted the
                Concert Hall sign—very likely the sign visible today on the
                facade. The tavern, with concert hall above, became a focus of
                activity in the community, with numerous plays, lectures,
                singing events, balls as well as music concerts given in the
                spacious hall. The saloon, "one of the largest and finest west
                of St. Louis," quickly acquired a pre-eminent reputation,
                serving as host to the growing number of St. Louis holiday
                excursion trains. In the summer of 1881, Lorenz Raus, a local
                builder, constructed a bowling alley as a one story ell to the
                rear of the building (since removed). In the spring of 1886
                Pfautsch and Kuhn erected a "summer garden" on an adjacent lot
                (the present location of the First Bank building) where outside
                band concerts were a regular feature with four covered pavilions
                and a wooden paling fence.
              </p>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}
